import * as anchor from "@project-serum/anchor";

const idl = require("../lib/nyan_staking_idl.json");

export const getTotalCTNP = async (wallet) => {
    try {
        const anchorProgram = await loadProgram(process.env.REACT_APP_CUSTOM_RPC, wallet);
        const [rewardStateData, _] = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from("pending_rewards_global_state")],
            anchorProgram.programId
        );
        const rewardState = await anchorProgram.account.rewardState.fetch(rewardStateData);
        const totalReward = (rewardState.rewardStopAt.toNumber()) -  (rewardState.totalCtnpDistributed.toNumber() + rewardState.totalCtnpOnExpedition.toNumber());
        return totalReward;
    } catch (ex) {
        console.log(ex);
    }
};

const getRewardState = async (wallet) => {
    try {
        const anchorProgram = await loadProgram(process.env.REACT_APP_CUSTOM_RPC, wallet);
        const [rewardStateData, _] = await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from("pending_rewards_global_state")],
            anchorProgram.programId
        );
        const rewardState = await anchorProgram.account.rewardState.fetch(rewardStateData);
        return rewardState;
    } catch (ex) {
        console.log(ex);
    }
};

export const isCTNPAvailable = async (wallet, items, duration) => {
    const rewardState = await getRewardState(wallet);
    const rewardStopAt = rewardState.rewardStopAt.toNumber();

    for (let k = 0; k < items.length; k++) {
        let json = items[k].json;
        let rarity = json.attributes.filter(l => l.trait_type == 'Rarity');
        if (rarity.length) {
            let reward = calculateReward(rarity[0].value, duration);
            if (reward + rewardState.totalCtnpDistributed.toNumber() + rewardState.totalCtnpOnExpedition.toNumber() < rewardStopAt) {
                return true;
            }
        }
    }

    return false;
}

async function loadProgram(customRpcUrl, wallet) {
    const connection = new anchor.web3.Connection(customRpcUrl);
    const provider = new anchor.AnchorProvider(
        connection,
        wallet,
        anchor.AnchorProvider.defaultOptions()
    );
    const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider);
    return program;
}

function calculateReward(rarity, duration) {
    rarity = rarity.toUpperCase();
    let reward;
    switch (duration) {
        case "7":
            switch (rarity) {
                case "COMMON":
                    reward = 152;
                    break;
                case "UNCOMMON":
                    reward = 242;
                    break;
                case "RARE":
                    reward = 501;
                    break;
                case "ULTRARARE":
                    reward = 1000;
                    break;
                case "LEGENDARY":
                    reward = 1750;
                    break;
            }
            break;
        case "14":
            switch (rarity) {
                case "COMMON":
                    reward = 365;
                    break;
                case "UNCOMMON":
                    reward = 581;
                    break;
                case "RARE":
                    reward = 1202;
                    break;
                case "ULTRARARE":
                    reward = 2400;
                    break;
                case "LEGENDARY":
                    reward = 4200;
                    break;
            }
            break;
        case "30":
            switch (rarity) {
                case "COMMON":
                    reward = 978;
                    break;
                case "UNCOMMON":
                    reward = 1555;
                    break;
                case "RARE":
                    reward = 3219;
                    break;
                case "ULTRARARE":
                    reward = 6427;
                    break;
                case "LEGENDARY":
                    reward = 11250;
                    break;
            }
            break;
    }
    return reward;
}

