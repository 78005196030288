import * as anchor from "@project-serum/anchor";
import idl from "../lib/nyan_staking_idl.json";
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as Sentry from "@sentry/react";
import { sendTransactions } from '../components/Connection';
import { Buffer } from "buffer";

export const unstake = async (connection, wallet, walletWrapper, item) => {
  let success = false;
  const provider = new anchor.AnchorProvider(connection, walletWrapper, {
    skipPreflight: true
  });
  const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

  const mintPublicKey = item.mint.address;

  const accounts = await connection.getTokenAccountsByOwner(wallet, { mint: mintPublicKey });

  let tokenAccount;
  const instructions = [];
  if (accounts.value.length > 0) {
    tokenAccount = accounts.value[0].pubkey
  } else {
    tokenAccount = await Token.getAssociatedTokenAddress(
      ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
      TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
      mintPublicKey, // mint
      wallet // owner
    );

    instructions.push(Token.createAssociatedTokenAccountInstruction(
      ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
      TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
      mintPublicKey, // mint
      tokenAccount, // ata
      wallet, // owner of token account
      wallet // fee payer
    ))
  }

  const stakedTokenAccount = item.stakedData.receiverNyanTokenAccount

  if (stakedTokenAccount.toString() === tokenAccount.toString()) {
    const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake")],
      program.programId
    );

    const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake_metadata")],
      program.programId
    );

    const allFetchedAccounts = JSON.stringify(accounts)
    Sentry.captureMessage(`Token Account: ${tokenAccount.toString()} - Wallet: ${wallet.toString()} - Stake Account: ${stakeAccount.toString()} - Stake Metadata Account: ${stakeMetadataAccount.toString()} - Receiver Nyan Token Account: ${item.stakedData.receiverNyanTokenAccount} - Receiver Nyan Mint Account: ${item.stakedData.receiverNyanMintAccount} - Mint Public Key: ${item.mint} - All Fetched Accounts: ${allFetchedAccounts}`);
    //tokenAccount
    //wallet.toBuffer()
    //stakeAccount
    //stakeMetadataAccount

    const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("global_state")],
      program.programId
    );

    const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("pending_rewards"), wallet.toBuffer()],
      program.programId
    );

    const [rewardStateData, rewardStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("pending_rewards_global_state")],
      program.programId
    );

    await program.rpc.unstake(
      {
        accounts: {
          receiver: program.provider.wallet.publicKey,
          receiverNyanTokenAccount: stakedTokenAccount,
          receiverNyanMintAccount: item.stakedData.receiverNyanMintAccount,
          stakeAccount: stakeAccount,
          stakeAccountData: stakeMetadataAccount,
          stakerRewards: userPendingRewards,
          globalState: globalStateData,
          rewardState: rewardStateData,
          tokenProgram: TOKEN_PROGRAM_ID
        },
        instructions
      }
    ).then(response => {
      success = true
    }).catch(error => {
    });
  } else {
    const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), stakedTokenAccount.toBuffer(), Buffer.from("stake")],
      program.programId
    );

    const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
      [wallet.toBuffer(), stakedTokenAccount.toBuffer(), Buffer.from("stake_metadata")],
      program.programId
    );

    const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("global_state")],
      program.programId
    );

    const [rewardStateData, rewardStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("pending_rewards_global_state")],
      program.programId
    );


    const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("pending_rewards"), wallet.toBuffer()],
      program.programId
    );

    await program.rpc.unstakeWithNewTokenAccount(
      {
        accounts: {
          receiver: program.provider.wallet.publicKey,
          receiverNyanTokenAccount: tokenAccount,
          receiverNyanMintAccount: item.stakedData.receiverNyanMintAccount,
          stakeAccount: stakeAccount,
          stakeAccountData: stakeMetadataAccount,
          stakerRewards: userPendingRewards,
          globalState: globalStateData,
          rewardState: rewardStateData,
          tokenProgram: TOKEN_PROGRAM_ID,
          oldTokenAccount: stakedTokenAccount
        },
        instructions
      }
    ).then(response => {
      success = true
    }).catch(error => {
    });
  }

  return success
};


export const unstakeAll = async (connection, wallet, walletWrapper, items) => {
  const provider = new anchor.AnchorProvider(connection, walletWrapper, {
    skipPreflight: true
  });
  const signers = [];
  const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

  const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("global_state")],
    program.programId
  );

  const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards"), wallet.toBuffer()],
    program.programId
  );

  const [rewardStateData, rewardStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards_global_state")],
    program.programId
  );


  try {
    const chunks = chunkArray(items, 30);
    const transactionsResponse = [];
    for (let j = 0; j < chunks.length; j++) {
      const chunk = chunks[j];
      const instructionsMatrix = [];
      const signersMatrix = [];
      for (let i = 0; i < chunk.length; i++) {
        const item = chunk[i];
        const mintPublicKey = item.mint.address;
        const accounts = await connection.getTokenAccountsByOwner(wallet, { mint: mintPublicKey });
        let tokenAccount;
        const instructions = [];
        if (accounts.value.length > 0) {
          tokenAccount = accounts.value[0].pubkey
        } else {
          tokenAccount = await Token.getAssociatedTokenAddress(
            ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
            TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
            mintPublicKey, // mint
            wallet // owner
          );

          instructions.push(Token.createAssociatedTokenAccountInstruction(
            ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
            TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
            mintPublicKey, // mint
            tokenAccount, // ata
            wallet, // owner of token account
            wallet // fee payer
          ))
        }

        const stakedTokenAccount = item.stakedData.receiverNyanTokenAccount

        if (stakedTokenAccount.toString() === tokenAccount.toString()) {
          const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
            [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake")],
            program.programId
          );

          const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
            [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake_metadata")],
            program.programId
          );

          const allFetchedAccounts = JSON.stringify(accounts)
          Sentry.captureMessage(`Token Account: ${tokenAccount.toString()} - Wallet: ${wallet.toString()} - Stake Account: ${stakeAccount.toString()} - Stake Metadata Account: ${stakeMetadataAccount.toString()} - Receiver Nyan Token Account: ${item.stakedData.receiverNyanTokenAccount} - Receiver Nyan Mint Account: ${item.stakedData.receiverNyanMintAccount} - Mint Public Key: ${item.mint} - All Fetched Accounts: ${allFetchedAccounts}`);


          instructions.push(
            await program.instruction.unstake(
              {
                accounts: {
                  receiver: program.provider.wallet.publicKey,
                  receiverNyanTokenAccount: stakedTokenAccount,
                  receiverNyanMintAccount: item.stakedData.receiverNyanMintAccount,
                  stakeAccount: stakeAccount,
                  stakeAccountData: stakeMetadataAccount,
                  stakerRewards: userPendingRewards,
                  globalState: globalStateData,
                  rewardState: rewardStateData,
                  tokenProgram: TOKEN_PROGRAM_ID
                },
                instructions
              }
            )
          )
        } else {
          const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
            [wallet.toBuffer(), stakedTokenAccount.toBuffer(), Buffer.from("stake")],
            program.programId
          );

          const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
            [wallet.toBuffer(), stakedTokenAccount.toBuffer(), Buffer.from("stake_metadata")],
            program.programId
          );


          instructions.push(
            await program.instruction.unstakeWithNewTokenAccount(
              {
                accounts: {
                  receiver: program.provider.wallet.publicKey,
                  receiverNyanTokenAccount: tokenAccount,
                  receiverNyanMintAccount: item.stakedData.receiverNyanMintAccount,
                  stakeAccount: stakeAccount,
                  stakeAccountData: stakeMetadataAccount,
                  stakerRewards: userPendingRewards,
                  globalState: globalStateData,
                  rewardState: rewardStateData,
                  tokenProgram: TOKEN_PROGRAM_ID,
                  oldTokenAccount: stakedTokenAccount
                },
                instructions
              }
            )
          )
        }

        instructionsMatrix.push(instructions);
        signersMatrix.push(signers);
      }

      const tx = await sendTransactions(
        program.provider.connection,
        program.provider.wallet,
        instructionsMatrix,
        signersMatrix
      );
      transactionsResponse.push(tx);
    }
    return transactionsResponse;
  }
  catch (error) {
    throw error;
  }
};

const chunkArray = (arr, size) => {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
}