import { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { ExpeditionLength } from "./ExpeditionLength";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from 'react-simple-snackbar'
import { embark } from "../lib/embark";
import { unstake } from "../lib/unstake";
import EmbarkDefault from "../assets/images/embark-wide-default.png";
import EmbarkHover from "../assets/images/embark-wide-hover.png";

const Image = styled.img`
  width: 300px;
  max-width: 80%;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  height: 266px;
  overflow: hidden;
  padding: 30px 50px;
  background-size: cover;
  background-position: center;
  ::before{
    content: ' ';
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.24em;
  text-transform: uppercase;
  color: #fff;
  @media(max-width: 992px){
    font-size: 18px;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  padding: 0 2rem;
  font-family: 'Gellix', sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
`;

const Name = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-transform: uppercase;
  margin: 10px 0 5px;
`

const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 20px;
  bottom: 0;
  flex-direction: column;
  align-items: center;
`;

const Multiplier = styled.div`
  position: relative;
  width: 126px;
  height: 18px;
  padding: 4px 0;
  line-height: 12px;
  font-family: 'Gellix', serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  background: #C34BFB;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  z-index: 3;
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3);
  z-index: 10;
`;

const GreenButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #257A5E;
  font-family: 'Gellix', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;

  :hover {
    background-color: #1d614b;
  }
`;

const GreyButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #565656;
  font-family: 'Gellix', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;

  :hover {
    background-color: #3a3a3a;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MissionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px 40px 20px;
  gap: 10px;
  margin-top: 30px;
  background: rgba(37, 122, 94, 0.2);
`

const MissionsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  outline: none !important;
  max-width: 60%;
  &:hover {
    cursor: pointer;
  }
`;

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  outline: none !important;
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #FFFFFF;
  &:hover {
    cursor: pointer;
  }
`

Modal.setAppElement('#root')

export function ExpeditionsModal({ item, setSelectedNFT, connection, wallet, setFetchedNFTs, fetchPendingRewards, fetchTotalCTNP }) {
    const [NFTData, setNFTData] = useState(null);
    const [duration, setDuration] = useState("7");
    const [loading, setLoading] = useState(false)
    const [openSnackbar, closeSnackbar] = useSnackbar({
        style: {
            zIndex: 4000
        }
    })
    const walletWrapper = useAnchorWallet()

    function closeModal() {
        setSelectedNFT(null)
    }

    useEffect(() => {
        async function getData() {
            let data = item
            setNFTData(data);
        }
        getData();
    }, [item]);

    const multipliers = {
        'Common': 'x1',
        'Uncommon': 'x1.59',
        'Rare': 'x3.29',
        'Ultrarare': 'x6.57',
        'Legendary': 'x11.5'
    };

    function rarity() {
        return NFTData?.json.attributes.filter(trait => (trait.trait_type && trait.trait_type === 'Rarity') || (trait.traitType && trait.traitType === 'Rarity'))[0].value;
    }

    const durationObject = {
        seven: 7,
        fourteen: 14,
        thirty: 30
    }

    function calculateProgress() {
        if (!item.stakedData) {
            return 0;
        }

        let calculatedDuration = durationObject[Object.keys(item.stakedData.duration)[0]]
        calculatedDuration = calculatedDuration * 86400;
        const countDownDate = new Date(item.stakedData.endTime.toNumber() * 1000).getTime()
        const startDate = new Date((item.stakedData.endTime.toNumber() - calculatedDuration) * 1000).getTime();

        // Update the count down every 1 second
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distanceWhole = countDownDate - startDate;
        var distanceLeft = countDownDate - now;

        // Time calculations for minutes and percentage progressed
        var minutesLeft = Math.floor(distanceLeft / (1000 * 60));
        var minutesTotal = Math.floor(distanceWhole / (1000 * 60));
        var progress = ((minutesTotal - minutesLeft) / minutesTotal) * 100;

        return progress
    }

    async function singleEmbark() {
        setLoading(true)
        const success = await embark(connection, wallet, walletWrapper, item, duration, rarity)

        if (success == true) {
            setTimeout(() => {
                openSnackbar("Your Nyan has been sent on Expedition!")
                setLoading(false)
                setSelectedNFT(null)
                setFetchedNFTs(false)
                setTimeout(() => {
                    fetchTotalCTNP()
                },10000)
            }, 1500)
        } else {
            if (success && success.error && success.error.errorCode && success.error.errorCode.code == 'LimitReached') {
                openSnackbar("CTNP limit reached.")
            } else {
                openSnackbar("Expedition rejected.")
                setLoading(false)
            }
        }

        setLoading(false)
    }

    async function singleUnstake() {
        setLoading(true)
        const success = await unstake(connection, wallet, walletWrapper, item)

        if (success) {
            setTimeout(() => {
                openSnackbar("Your Nyan is now back from expedition!")
                setLoading(false)
                setSelectedNFT(null)
                setFetchedNFTs(false)
                fetchPendingRewards()
                setTimeout(() => {
                    fetchTotalCTNP()
                },10000)
            }, 1500)
        } else {
            openSnackbar("Expedition recalling failed.")
            setLoading(false)
        }

        setLoading(false)
    }

    if (item) {
        return (
            <Modal
                isOpen={item !== null}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                className="expeditions-modal"
                overlayClassName="overlay"
            >
                <Container>
                    <InfoContainer>
                        <Image src={NFTData?.json.image} />
                        <Name>{item.json.name}</Name>
                        <Multiplier>{multipliers[rarity()]} Multiplier</Multiplier>
                    </InfoContainer>
                    {!item.stakedData ? (
                        <MissionContainer>
                            <Title>Choose Your Mission</Title>
                            <MissionsWrapper>
                                <ExpeditionLength value="7" selectedDuration={duration} setter={setDuration} />
                                <ExpeditionLength value="14" selectedDuration={duration} setter={setDuration} />
                                <ExpeditionLength value="30" selectedDuration={duration} setter={setDuration} />
                            </MissionsWrapper>
                            <ButtonsWrapper>
                                <Button onClick={() => (singleEmbark())}>
                                    <img className="img-fluid" src={EmbarkDefault} onMouseOver={e => (e.currentTarget.src = EmbarkHover)} onMouseOut={e => (e.currentTarget.src = EmbarkDefault)} />
                                </Button>
                                <TextButton onClick={() => (setSelectedNFT(null))}>Cancel</TextButton>
                            </ButtonsWrapper>
                        </MissionContainer>
                    ) : (
                        <MissionContainer>
                            {calculateProgress() < 100 ? (
                                <div>
                                    <Title>Cancel Expedition?</Title>
                                    <SubTitle>You will not earn any $CTNP if you end an expedition early.</SubTitle>
                                    <ButtonsWrapper className="d-flex flex-row flex-wrap">
                                        <div className="col-12 col-md-6 px-0">
                                            <GreenButton onClick={() => (setSelectedNFT(null))} className="blue">Continue Expedition</GreenButton>
                                        </div>
                                        <div className="col-12 col-md-6 px-0 mt-3 mt-md-0">
                                            <GreyButton onClick={() => (singleUnstake())}>End Expedition</GreyButton>
                                        </div>
                                    </ButtonsWrapper>
                                </div>
                            ) : (
                                <div>
                                    <Title>Expedition Finished!</Title>
                                    <SubTitle>End the expedition to claim your $CTNP. Remember to send it on expedition again, to continue earning $CTNP.</SubTitle>
                                    <ButtonsWrapper className="d-flex flex-row">
                                        <div className="col-12 px-0">
                                            <GreenButton onClick={() => (singleUnstake())}>End Expedition</GreenButton>
                                        </div>
                                    </ButtonsWrapper>
                                </div>
                            )}
                        </MissionContainer>
                    )}
                    {loading && (
                        <Loading>
                            <div className="loader loader-1">
                                <div className="loader-outter"></div>
                                <div className="loader-inner"></div>
                            </div>
                        </Loading>
                    )}
                </Container>
            </Modal>
        );
    } else {
        return null;
    }
}
