import styled from "styled-components";
import forest from "../assets/images/forest.png"
import desert from "../assets/images/desert.png"
import tundra from "../assets/images/tundra.png"

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 340px;
  padding: 0 15px;
  border-radius: 5px;
  &:hover{
    box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.05);
  }
  &.active{
    box-shadow:inset 0 0 0 2000px rgba(255, 255, 255, 0.1);
  }
  @media (max-width: 767px){
    margin-bottom: 20px;
  }
`

const ItemContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 180px;
  max-height: 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  cursor: pointer;
  &::after{
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 180px;
    max-height: 180px;
    background: rgba(0,0,0,0.3);
    border-radius: 50%;
    z-index: 1;
  }
`;

const Image = styled.img`
  position: relative;
  top: 0;
  height: auto;
  max-width: 100%;
  border-radius: 50%;
  z-index: 2;
`;

const Title = styled.div`
  margin-top: 10px;
  font-family: 'Gellix';
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const Days = styled.div`
  font-family: 'Gellix';
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
`;

const Multiplier = styled.div`
  width: 180px;
  background: #257A5E;
  padding: 0 10px;
  margin-top: 10px;
  border-radius: 15px;
  font-family: 'Gellix';
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
`;

export function ExpeditionLength({ value, selectedDuration, setter }) {
  const multiplier = {
    7: 'x1',
    14: 'x1.2',
    30: 'x1.5'
  }
  const name = {
    7: 'Forest',
    14: 'Desert',
    30: 'Tundra'
  }
  const background = {
    7: forest,
    14: desert,
    30: tundra
  }

  const classes = selectedDuration === value ? 'active col-12 col-md-4' : 'col-12 col-md-4';

  return (
      <ItemWrapper className={classes} onClick={() => setter(value)}>
        <ItemContainer>
          <Image src={background[value]} />
        </ItemContainer>
        <Title>{ name[value] }</Title>
        <Days>{ value } Days</Days>
        <Multiplier>{ multiplier[value] } Multiplier</Multiplier>
      </ItemWrapper>
  );
}
