import { useState, useEffect } from "react";
import styled from "styled-components";
import * as anchor from '@project-serum/anchor'
import { TOKEN_PROGRAM_ID, Token, ASSOCIATED_TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from 'react-simple-snackbar'

const idl = require("../lib/nyan_staking_idl.json")

const Button = styled.button`
  width: 100%;
  height: 50px;
  background-color: #DB2E44;
  font-family: 'Gellix', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;
`;

export function UnStake({ connection, wallet, item, setSelectedNFT, setFetchedNFTs, setLoading, fetchPendingRewards, finished = false }) {
    const [openSnackbar, closeSnackbar] = useSnackbar({
        style: {
            zIndex: 4000
        }
    })
    const walletWrapper = useAnchorWallet()

    async function unstake() {
      setLoading(true)
      const provider = new anchor.AnchorProvider(connection, walletWrapper, {
          skipPreflight: true
      });
      const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

      const mintPublicKey = new anchor.web3.PublicKey(item.mint)

      const accounts = await connection.getTokenAccountsByOwner(wallet, {mint: mintPublicKey});
      let tokenAccount;
      const instructions = [];
      if (accounts.value.length > 0) {
          tokenAccount = accounts.value[0].pubkey
      } else {
        // tokenAccount = await Token.getAssociatedTokenAddress(
        //     ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
        //     TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
        //     mintPublicKey, // mint
        //     wallet // owner
        // );
          tokenAccount = item.stakedData.receiverNyanTokenAccount;

          instructions.push(Token.createAssociatedTokenAccountInstruction(
              ASSOCIATED_TOKEN_PROGRAM_ID, // always ASSOCIATED_TOKEN_PROGRAM_ID
              TOKEN_PROGRAM_ID, // always TOKEN_PROGRAM_ID
              mintPublicKey, // mint
              tokenAccount, // ata
              wallet, // owner of token account
              wallet // fee payer
          ))
      }

      const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake")],
          program.programId
      );

      const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake_metadata")],
          program.programId
      );

      const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from("global_state")],
          program.programId
      );

      const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
          [Buffer.from("pending_rewards"), wallet.toBuffer()],
          program.programId
      );

      await program.rpc.unstake(
          {
              accounts: {
                  receiver: program.provider.wallet.publicKey,
                  receiverNyanTokenAccount: item.stakedData.receiverNyanTokenAccount,
                  receiverNyanMintAccount: item.stakedData.receiverNyanMintAccount,
                  stakeAccount: stakeAccount,
                  stakeAccountData: stakeMetadataAccount,
                  stakerRewards: userPendingRewards,
                  globalState: globalStateData,
                  tokenProgram: TOKEN_PROGRAM_ID
              },
              instructions
          }
      ).then(response => {
          setTimeout(() => {
              openSnackbar("Your Nyan is now back from expedition!")
              setLoading(false)
              setSelectedNFT(null)
              setFetchedNFTs(false)
              fetchPendingRewards()
          }, 1500)
      }).catch(error => {
          openSnackbar("Expedition recalling failed.")
          setLoading(false)
      });
  }

  return (
    <Button onClick={unstake}>
        End Expedition
    </Button>
  );
}
