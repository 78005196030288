import { NFTContainer } from "../components/NFTContainer";
import styled from "styled-components";
import { pendingRewards } from "../lib/stats";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Title = styled.div`
  color: #FDF8FF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 34px #fdf6a0;
  font-size: 40px;
`;

const InfoText = styled.div`
  color: #FFFFFF;
  text-align: left;
  margin-top: 50px;
  align: Justified;
  font-size: 18px;
`;

export default function Expeditions() {
    return (
        <div>
            <section className="expeditions-section">
                <div className="container mb-5">
                    <Header>
                        <Title className="headline-2">Expedition Program :<br></br> A NEW JOURNEY AHEAD</Title>
                    </Header>
                    <InfoText className="body-1">
                        Hello Nyan Adventurers,
                        <br /><br />
                        While the Nyan Heroes Expedition Program has come to an end, this page remains live for you to connect your wallet and verify your $CTNP balance. We've ensured that all contracts are securely closed, and every participant's $CTNP has been accurately allocated.
                        <br /><br />
                        Simply connect your wallet to review your balance. And if you ever need assistance, our support team on <a href="https://discord.com/invite/nyanheroes" target="_blank">Discord</a> is a click away.
                        <br /><br />
                        We're excited about the journey ahead and hope to see you in Nekovia!
                    </InfoText>
                </div>
                <NFTContainer />
            </section>
        </div>
    )
}