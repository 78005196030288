import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {useWallet} from "@solana/wallet-adapter-react";

import defaultImg from "../assets/images/connect-wallet-default.png"
import hoverImg from "../assets/images/connect-wallet-hover.png"

export function
ConnectButton() {
    const {publicKey} = useWallet()
    return (
        <WalletMultiButton className="btn-reset cursor-pointer" startIcon={null}>
            <img className="btn-main" src={defaultImg} onMouseOver={e => (e.currentTarget.src = hoverImg)} onMouseOut={e => (e.currentTarget.src = defaultImg)}/>
            {/*{ !publicKey ? (*/}
            {/*    <span>Connect</span>*/}
            {/*) : (*/}
            {/*    <span>{publicKey.toString().slice(0, 4)}...{publicKey.toString().slice(publicKey.toString().length - 4, publicKey.toString().length)}</span>*/}
            {/*)}*/}
        </WalletMultiButton>
    )
}