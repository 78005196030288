import { PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import idl from "../lib/nyan_staking_idl.json";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { sendTransactions } from '../components/Connection';
import { Buffer } from "buffer";

const stakeDurationMap = {
  7: { seven: {} },
  14: { fourteen: {} },
  30: { thirty: {} },
}

export const embark = async (connection, wallet, walletWrapper, item, duration, rarity) => {
  let success = false;
  const provider = new anchor.AnchorProvider(connection, walletWrapper, {
    skipPreflight: true
  });
  const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

  const mintPublicKey = item.mint.address;

  const accounts = await connection.getTokenAccountsByOwner(wallet, { mint: mintPublicKey });
  const tokenAccount = accounts.value[0].pubkey

  const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
    [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake")],
    program.programId
  );

  const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
    [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake_metadata")],
    program.programId
  );

  const [rewardStateData, rewardStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards_global_state")],
    program.programId
  );

  const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("global_state")],
    program.programId
  );

  const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards"), wallet.toBuffer()],
    program.programId
  );

  const [mintPda, bump] = await PublicKey.findProgramAddress(
    [
      Buffer.from(anchor.utils.bytes.utf8.encode("nyan_mint_metadata")),
      mintPublicKey.toBuffer(),
    ],
    program.programId
  );

  const stakeDuration = stakeDurationMap[duration];

  await program.rpc.stake(
    stakeDuration,
    {
      accounts: {
        staker: program.provider.wallet.publicKey,
        stakeAccount: stakeAccount,
        stakeAccountData: stakeMetadataAccount,
        stakerRewards: userPendingRewards,
        globalState: globalStateData,
        nyanMint: mintPublicKey,
        nyanToken: tokenAccount,
        nyanMintMetadata: mintPda,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: anchor.web3.SystemProgram.programId,
        rewardState: rewardStateData,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      }
    }
  ).then(async response => {
    success = true
  }).catch(error => {
    success = error
  });

  return success
};


export const embarkAll = async (connection, wallet, walletWrapper, items, duration, rarity) => {
  const provider = new anchor.AnchorProvider(connection, walletWrapper, {
    skipPreflight: true
  });
  const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

  const [globalStateData, globalStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("global_state")],
    program.programId
  );

  const [rewardStateData, rewardStateDataBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards_global_state")],
    program.programId
  );

  const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from("pending_rewards"), wallet.toBuffer()],
    program.programId
  );

  const signers = [];

  try {
    const chunks = chunkArray(items, 30);
    const transactionsResponse = [];
    for (let j = 0; j < chunks.length; j++) {
      const chunk = chunks[j];
      const instructionsMatrix = [];
      const signersMatrix = [];
      for (let i = 0; i < chunk.length; i++) {
        const item = chunk[i];
        const instructions = [];
        const mintPublicKey = item.mint.address;

        const accounts = await connection.getTokenAccountsByOwner(wallet, { mint: mintPublicKey });
        const tokenAccount = accounts.value[0].pubkey

        const [stakeAccount, stakeAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake")],
          program.programId
        );

        const [stakeMetadataAccount, stakeMetadataAccountBump] = await anchor.web3.PublicKey.findProgramAddress(
          [wallet.toBuffer(), tokenAccount.toBuffer(), Buffer.from("stake_metadata")],
          program.programId
        );


        const [mintPda, bump] = await PublicKey.findProgramAddress(
          [
            Buffer.from(anchor.utils.bytes.utf8.encode("nyan_mint_metadata")),
            mintPublicKey.toBuffer(),
          ],
          program.programId
        );

        const stakeDuration = stakeDurationMap[duration];

        instructions.push(
          await program.instruction.stake(
            stakeDuration,
            {
              accounts: {
                staker: program.provider.wallet.publicKey,
                stakeAccount: stakeAccount,
                stakeAccountData: stakeMetadataAccount,
                stakerRewards: userPendingRewards,
                globalState: globalStateData,
                nyanMint: mintPublicKey,
                nyanToken: tokenAccount,
                nyanMintMetadata: mintPda,
                tokenProgram: TOKEN_PROGRAM_ID,
                systemProgram: anchor.web3.SystemProgram.programId,
                rewardState: rewardStateData,
                rent: anchor.web3.SYSVAR_RENT_PUBKEY,
              }
            }
          )
        )
        instructionsMatrix.push(instructions);
        signersMatrix.push(signers);
      }
      const tx = await sendTransactions(
        program.provider.connection,
        program.provider.wallet,
        instructionsMatrix,
        signersMatrix
      );
      transactionsResponse.push(tx);
    }
    return transactionsResponse;
  } catch (error) {
    throw error;
  }
};


const chunkArray = (arr, size) => {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
}