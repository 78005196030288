import { useState, useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { ExpeditionLength } from "./ExpeditionLength";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useSnackbar } from 'react-simple-snackbar'
import { embark, embarkAll } from "../lib/embark";
import { unstake, unstakeAll } from "../lib/unstake";
import { isCTNPAvailable } from '../lib/fetchCtnp';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  font-family: 'Gellix', sans-serif;
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  color: #fff;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  margin: 1rem 0;
  font-family: 'Gellix', sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  @media(max-width: 992px){
    font-size: 18px;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  font-family: 'Gellix', sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
`;

const Name = styled.div`
  font-family: 'Gellix', sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff;
`

const ButtonsWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  bottom: 0;
`;

const Multiplier = styled.div`
  height: 26px;
  padding: 4px 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: #DB2E44;
  border-radius: 12px;
  color: #fff;
`

const Cancel = styled.button`
  width: 100%;
  height: 50px;
  background-color: #323232;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;
  &.blue{
    background-color: #328af0;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3);
  z-index: 10;
`;

const RedButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #DB2E44;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;
`;

const GreenButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #257A5E;
  font-family: 'Gellix', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;

  :hover {
    background-color: #1d614b;
  }
`;

const GreyButton = styled.button`
  width: 100%;
  height: 50px;
  background-color: #565656;
  font-family: 'Gellix', sans-serif;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  border: 0;
  outline: 0 !important;

  :hover {
    background-color: #3a3a3a;
  }
`;

const MissionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px 40px 20px;
  gap: 10px;
  margin-top: 30px;
  background: rgba(37, 122, 94, 0.2);
`

const MissionsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

Modal.setAppElement('#root')

export function ExpediteAllModal({ show, items, connection, wallet, setShowExpediteModal, setFetchedNFTs, endAll, fetchTotalCTNP }) {
    const [duration, setDuration] = useState("7");
    const [loading, setLoading] = useState(false)
    const [openSnackbar, closeSnackbar] = useSnackbar({
        style: {
            zIndex: 4000
        }
    })
    const walletWrapper = useAnchorWallet()

    function closeModal() {
        setShowExpediteModal(false)
    }

    async function getData(item) {
        return await (await fetch(item?.data?.uri)).json();
    }

    function rarity(data) {
        return data.attributes.filter(trait => trait.trait_type === 'Rarity')[0].value
    }

    async function multiEmbark() {
        setLoading(true)
        let success = true;
        let message = null;

        try {
            const filteredItems = items.filter(k => k.stakedData == null);
            const isAvailable = await isCTNPAvailable(wallet, filteredItems, duration);         
            if (!isAvailable && filteredItems.length) {
                throw new Error("CTNP limit reached.");
            }

            const response = await embarkAll(connection, wallet, walletWrapper, filteredItems, duration, null);
            if (!response)
                throw new Error("Embark all failed, try again.");
        }
        catch (e) {
            success = false;
            if (e.message == 'CTNP limit reached.') {
                message = 'CTNP limit reached.'
            } else {
                message = `Embark all failed, try again.`
            }
        }

        if (success) {
            setTimeout(() => {
                setFetchedNFTs(false)
                setShowExpediteModal(false)
                setTimeout(() => {
                    fetchTotalCTNP()
                },10000)
                openSnackbar("Your Nyans has been sent on Expedition!")
            }, 1500)
        } else {
            setFetchedNFTs(false)
            setTimeout(() => {
                fetchTotalCTNP()
            },10000)
            if (message) {
                openSnackbar(message)
            } else {
                openSnackbar("Embark all failed, try again.")
            }
        }
        setLoading(false)
    }

    async function multiUnstake() {
        setLoading(true)
        let success = true;

        try {
            const filteredList = items.filter(k => k.stakedData != null);
            const response = await unstakeAll(connection, wallet, walletWrapper, filteredList);
            if (!response)
                throw new Error("Unstaking all failed, try again.");
        }
        catch (e) {
            console.log(e);
            success = false;
            openSnackbar(`Unstaking all failed, try again.`)
        }

        if (success) {
            setTimeout(() => {
                setFetchedNFTs(false)
                setShowExpediteModal(false)
                setTimeout(() => {
                    fetchTotalCTNP()
                },10000)
                openSnackbar("Your Nyans has been returned from Expedition!")
            }, 1500)
        } else {
            openSnackbar("Unstaking all failed, try again.")
        }
        setLoading(false)
    }

    if (show) {
        return (
            <Modal
                isOpen={show !== false}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                className="expeditions-modal"
                overlayClassName="overlay"
            >
                <Container>
                    <Header>
                        {endAll ? (
                            <span>Unstake all your Nyans</span>
                        ) : (
                            <span>Expedite all your Nyans</span>
                        )}
                    </Header>
                    <div>
                        {endAll ? (
                            <div>
                                <Title>This will end ALL your Expeditions</Title>
                                <SubTitle>Both finished, and unfinished Expeditions. There's no going back.</SubTitle>
                                <ButtonsWrapper className="d-flex flex-row flex-wrap">
                                    <div className="col-12 col-md-6 px-0">
                                        <GreenButton onClick={() => (multiUnstake())}>Unstake All</GreenButton>
                                    </div>
                                    <div className="col-12 col-md-6 px-0 mt-3 mt-md-0">
                                        <Cancel onClick={() => (closeModal())}>Cancel</Cancel>
                                    </div>
                                </ButtonsWrapper>
                            </div>
                        ) : (
                            <MissionContainer>
                                <Title>Choose Their Mission</Title>
                                <MissionsWrapper>
                                    <ExpeditionLength value="7" selectedDuration={duration} setter={setDuration} />
                                    <ExpeditionLength value="14" selectedDuration={duration} setter={setDuration} />
                                    <ExpeditionLength value="30" selectedDuration={duration} setter={setDuration} />
                                </MissionsWrapper>
                                <ButtonsWrapper className="d-flex flex-row flex-wrap">
                                    <div className="col-12 col-md-6 px-0">
                                        {/* <GreenButton onClick={() => (multiEmbark())}>Embark All</GreenButton> */}
                                    </div>
                                    <div className="col-12 col-md-6 px-0 mt-3 mt-md-0">
                                        <Cancel onClick={() => (closeModal())}>Cancel</Cancel>
                                    </div>
                                </ButtonsWrapper>
                            </MissionContainer>
                        )}

                    </div>
                    {loading && (
                        <Loading>
                            <div className="loader loader-1">
                                <div className="loader-outter"></div>
                                <div className="loader-inner"></div>
                            </div>
                        </Loading>
                    )}
                </Container>
            </Modal>
        );
    } else {
        return null;
    }
}
