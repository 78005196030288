import { Buffer } from "buffer";
import * as anchor from "@project-serum/anchor";
import idl from "../lib/nyan_staking_idl.json";

export const pendingRewards = async (connection, wallet, walletWrapper) => {
    const provider = new anchor.AnchorProvider(connection, walletWrapper, {
        skipPreflight: true
    });
    const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)

    const [userPendingRewards, userPendingRewardsBump] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from("pending_rewards"), wallet.toBuffer()],
        program.programId
    );

    let userRewards = null;

    await program.account.pendingRewards.fetch(userPendingRewards).then(response => {
        userRewards = response
    }).catch(error => {

    });

    if (userRewards) {
        return userRewards.pendingRewards.toNumber();
    } else {
        return 0;
    }
};