import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Keypair, PublicKey } from "@solana/web3.js";

import { fetchNFTsOwnedByWallet } from "../lib/fetchNFTsByWallet";

import { NFTItem } from "./NFTItem";
import { ExpeditionsModal } from "./ExpeditionsModal";
import { ConnectButton } from "./ConnectButton";
import { ExpediteAllModal } from "./ExpediteAllModal";

import EmbarkDefault from "../assets/images/embark-wide-default.png"
import EmbarkHover from "../assets/images/embark-wide-hover.png"
import EmbarkAllDefault from "../assets/images/embark-all-default.png"
import EmbarkAllHover from "../assets/images/embark-all-hover.png"
import FetchNyansDefault from "../assets/images/fetch-nyans-default.png"
import FetchNyansHover from "../assets/images/fetch-nyans-hover.png"
import UnstakeAllDefault from "../assets/images/unstake-all-default.png"
import UnstakeAllHover from "../assets/images/unstake-all-hover.png"
import hoverImg from "../assets/images/connect-wallet-hover.png";
import defaultImg from "../assets/images/connect-wallet-default.png";
import { pendingRewards } from "../lib/stats";
import { getTotalCTNP } from '../lib/fetchCtnp';
import { NumberDisplay } from '../components/NumberDisplay';

const Container = styled.div`
  padding: 20px 30px;
`;

const LightContainer = styled(Container)`
  background: rgba(37, 122, 94, 0.2);
  border: 1px solid rgba(112, 224, 187, 0.2);
`;

const DarkContainer = styled(Container)`
  background: rgba(37, 122, 94, 0.2);
  opacity: 0.6;
  border: 1px solid rgba(112, 224, 187, 0.2);
`;

const ButtonContainer = styled(Container)`
  @media  (max-width: 767px) {
    padding: 15px 0;
  }
`;

const Text = styled.div`
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 20px;
`;

const WalletInfo = styled.div`
  @media  (max-width: 767px) {
    font-size: 10px;
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  outline: none !important;
  &:hover {
    cursor: pointer;
  }
`;

const CTNPContainer = styled.div`
  max-width: 50%;
  padding: 20px 50px;
  background: rgba(37, 122, 94, 0.4);
  border: 1px solid rgba(112, 224, 187, 0.4);
  margin-top: 25px;
  @media  (max-width: 767px) {
    max-width: 100%;
  }
`;

const CTNPCounter = styled.div`
  padding: 20px 50px;
  margin-top: 25px;
  @media  (max-width: 767px) {
    max-width: 100%;
  }
`;

const Text1 = styled.div`
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 20px;
`;

export function NFTContainer({ network }) {
    const [rewards, setRewards] = useState(0)
    const { connection } = useConnection();
    const { publicKey } = useWallet();
    const [fetchedNFTs, setFetchedNFTs] = useState(null);
    const [NFTs, setNFTs] = useState(null);
    const [selectedNFT, setSelectedNFT] = useState(null)
    const [showExpediteModal, setShowExpediteModal] = useState(false)
    const [endAll, setEndAll] = useState(false)
    const walletWrapper = useAnchorWallet()
    const [ctnpCounter, setCtnpCounter] = useState(null);

    useEffect(async () => {
        if (publicKey) {
            await fetchPendingRewards()
        }
    }, [publicKey]);

    async function fetchPendingRewards() {
        setRewards(await pendingRewards(connection, publicKey, walletWrapper))
    }

    async function fetchTotalCTNP() {
        let keypair = Keypair.generate();
        let totalRewards = await getTotalCTNP(new PublicKey(keypair.publicKey));
        setCtnpCounter(totalRewards);
    }

    async function fetchNFTs() {
        if (!publicKey) return setNFTs(null);
        let NFTs = await fetchNFTsOwnedByWallet(
            new PublicKey(publicKey),
            connection
        );
        if (typeof NFTs === "undefined") {
            setNFTs(0);
        } else {
            setNFTs(NFTs);
        }
    }

    function EmbarkAll() {
        setEndAll(false)
        setShowExpediteModal(true)
    }

    function UnstakeAll() {
        setEndAll(true)
        setShowExpediteModal(true)
    }


    if (!ctnpCounter) {
        fetchTotalCTNP();
    }

    if (publicKey) {
        if (!fetchedNFTs) {
            setFetchedNFTs(true)
            fetchNFTs()
        }

        if (NFTs === 0) {
            return (
                <div>
                    {/* <CTNPCounter>
                        <Text1 className="headline-7">CTNP COUNTER</Text1>
                        <Text1 className="headline-8 text-center">
                            {
                                <span><NumberDisplay number={ctnpCounter} /></span>
                            }
                        </Text1>
                    </CTNPCounter> */}
                    <LightContainer>
                        <Text className="headline-4">Step 1_Connect Wallet</Text>
                        <p>
                            No NFTs found for <strong>{publicKey.toString()}</strong> on{" "}
                            <strong>{network}</strong>!
                        </p>
                        <Button onClick={fetchNFTs}>
                            <img className="img-fluid" src={FetchNyansDefault} onMouseOver={e => (e.currentTarget.src = FetchNyansHover)} onMouseOut={e => (e.currentTarget.src = FetchNyansDefault)} />
                        </Button>
                    </LightContainer>
                </div>
            );
        }
        return (
            <div>
                {/* <CTNPCounter>
                    <Text1 className="headline-7">CTNP COUNTER</Text1>
                    <Text1 className="headline-8 text-center">
                        {
                            <span><NumberDisplay number={ctnpCounter} /></span>
                        }
                    </Text1>
                </CTNPCounter> */}
                <LightContainer className="container">
                    <Text className="headline-4">Step 1_Connect Wallet</Text>
                    <WalletInfo className="d-flex justify-content-center align-items-center text-center text-white py-5 headline-6 text-uppercase">
                        Successfully connected to wallet<br />
                        {publicKey.toString().slice(0, 4)}...{publicKey.toString().slice(publicKey.toString().length - 4, publicKey.toString().length)}
                    </WalletInfo>
                </LightContainer>
                <LightContainer className="container nft-container">
                    <Text className="headline-4">Step 2_Select Nyan Hero & Mission</Text>
                    <div className="d-flex justify-content-center">
                        <CTNPContainer>
                            <Text className="headline-4">Your Total $CTNP</Text>
                            <Text className="headline-4 text-center">
                                {publicKey ? (
                                    <span>{new Intl.NumberFormat().format(rewards)}</span>
                                ) : (
                                    <span>0</span>
                                )}
                            </Text>
                        </CTNPContainer>
                    </div>
                    <ButtonContainer className="d-flex justify-content-center flex-wrap">
                        <Button className="col-12 col-md-4" onClick={fetchNFTs}>
                            <img className="img-fluid" src={FetchNyansDefault} onMouseOver={e => (e.currentTarget.src = FetchNyansHover)} onMouseOut={e => (e.currentTarget.src = FetchNyansDefault)} />
                        </Button>
                        {/* <Button className="col-12 col-md-4" onClick={() => (EmbarkAll())}>
                            <img className="img-fluid" src={EmbarkAllDefault} onMouseOver={e => (e.currentTarget.src = EmbarkAllHover)} onMouseOut={e => (e.currentTarget.src = EmbarkAllDefault)} />
                        </Button> */}
                        <Button className="col-12 col-md-4" onClick={() => (UnstakeAll())}>
                            <img className="img-fluid" src={UnstakeAllDefault} onMouseOver={e => (e.currentTarget.src = UnstakeAllHover)} onMouseOut={e => (e.currentTarget.src = UnstakeAllDefault)} />
                        </Button>
                    </ButtonContainer>
                    <Container className="container p-0">
                        <div className="row w-100">
                            {
                                NFTs &&
                                NFTs.map((item) => {
                                    if (item === "") return null;
                                    return (
                                        <NFTItem key={JSON.stringify(item)} item={item} setSelectedNFT={setSelectedNFT} />
                                    );
                                })}
                        </div>
                    </Container>
                </LightContainer>

                <ExpeditionsModal item={selectedNFT} setSelectedNFT={setSelectedNFT} connection={connection}
                    wallet={publicKey} setFetchedNFTs={setFetchedNFTs} fetchPendingRewards={fetchPendingRewards} fetchTotalCTNP={fetchTotalCTNP} />
                <ExpediteAllModal show={showExpediteModal} items={NFTs} setShowExpediteModal={setShowExpediteModal} connection={connection}
                    wallet={publicKey} setFetchedNFTs={setFetchedNFTs} endAll={endAll} fetchTotalCTNP={fetchTotalCTNP} />
            </div>
        );
    } else {
        return (
            <div>
                {/* <CTNPCounter>
                    <Text1 className="headline-7">CTNP COUNTER</Text1>
                    <Text1 className="headline-8 text-center">
                        {
                            <span><NumberDisplay number={ctnpCounter} /></span>
                        }
                    </Text1>
                </CTNPCounter> */}
                <LightContainer className="container">
                    <Text className="headline-4">Step 1_Connect Wallet</Text>
                    <div className="d-flex justify-content-center align-items-center py-5">
                        <ConnectButton />
                    </div>
                </LightContainer>
                <DarkContainer className="container">
                    <Text className="headline-4">Step 2_Select Nyan Hero & Mission</Text>
                </DarkContainer>
            </div>
        )
    }
}
