import './assets/css/bootstrap.min.css';
import './assets/css/plugin.css';
import './assets/scss/style.scss';

import { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'

import logo from './assets/images/logo-shadow.png'
import twitter from './assets/images/twitter.svg'
import discord from './assets/images/discord.svg'
import instagram from './assets/images/instagram.svg'
import telegram from './assets/images/telegram.svg'
import youtube from './assets/images/youtube.svg'
import {WalletConnectionProvider} from "./components/WalletConnectionProvider";
import {WalletModalProvider, WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {ConnectButton} from "./components/ConnectButton";

function App() {
  const [network, setNetwork] = useState(process.env.REACT_APP_CUSTOM_RPC);

  return (
      <WalletConnectionProvider network={network}>
        <WalletModalProvider>
          <SnackbarProvider>
            <div className="App">
              <header className="header">
                <div className="mainmenu-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-center" id="main_menu">
                            <img src={logo} className="header-logo"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <br></br>
              <Outlet />

              <footer className="footer" id="footer" style={{position: 'fixed', bottom: 0, width: '100%', marginTop: '0', paddingTop: '0'}}>
                <div className="copy-bg" style={{marginTop: 0}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="left-area">
                          <p>“Nyan Heroes™” and all related products, services, trademarks, logos and brand names are the property of 9 Lives Interactive Pte. Ltd. All rights reserved. All other copyrights and trademarks are the property of their respective owners.</p>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-3 mt-lg-0">
                        <ul className="copright-area-links">
                          <li>
                            <a href="https://twitter.com/nyanheroes" target="_blank">
                              <img src={twitter} width="24"/>
                            </a>
                          </li>
                          <li>
                            <a href="https://discord.gg/nyanheroes" target="_blank">
                              <img src={discord} width="24"/>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/nyanheroesgame/" target="_blank">
                              <img src={instagram} width="24"/>
                            </a>
                          </li>
                          <li>
                            <a href="https://t.me/nyanheroes/" target="_blank">
                              <img src={telegram} width="24"/>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.youtube.com/channel/UCjL9JLuurNx4p6o8sttWwDA/" target="_blank">
                              <img src={youtube} width="24"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>

              <script type="text/javascript" src="assets/js/jquery.js"></script>
              <script type="text/javascript" src="assets/js/popper.min.js"></script>
              <script type="text/javascript" src="assets/js/bootstrap.min.js"></script>
              <script type="text/javascript" src="assets/js/plugin.js"></script>
              <script type="text/javascript" src="assets/js/main.js"></script>
              <script type="text/javascript" src="assets/js/aos.js"></script>
              <script>
                AOS.init();
              </script>
            </div>
          </SnackbarProvider>
        </WalletModalProvider>
      </WalletConnectionProvider>
  );
}

export default App;
