import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../src/assets/scss/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Expeditions from "./routes/expeditions";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

require("@solana/wallet-adapter-react-ui/styles.css");

// Sentry.init({
//     dsn: "https://f0381772a9a0490a967cbe91e3df4af7@o1149692.ingest.sentry.io/6222074",
//     integrations: [new BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<App />}>
                  <Route index element={<Expeditions />}/>
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
