import {
    Keypair,
    Commitment,
    Connection,
    RpcResponseAndContext,
    SignatureStatus,
    SimulatedTransactionResponse,
    Transaction,
    TransactionInstruction,
    TransactionSignature,
    Blockhash,
    FeeCalculator,
} from "@solana/web3.js";

import { WalletNotConnectedError } from "@solana/wallet-adapter-base";


export const DEFAULT_TIMEOUT = 60000;

export const getErrorForTransaction = async (
    connection,
    txid
) => {
    // wait for all confirmation before geting transaction
    try {
        await connection.confirmTransaction(txid, "max");

        const tx = await connection.getParsedConfirmedTransaction(txid);

        const errors = [];
        if (tx?.meta && tx.meta.logMessages) {
            tx.meta.logMessages.forEach((log) => {
                const regex = /Error: (.*)/gm;
                let m;
                while ((m = regex.exec(log)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }

                    if (m.length > 1) {
                        errors.push(m[1]);
                    }
                }
            });
        }

        return errors;
    } catch (error) {
        throw error;
    }
};


export const sendTransactions = async (
    connection,
    wallet,
    instructionSet,
    signersSet,
    sequenceType = "Parallel",
    commitment = "confirmed",
    successCallback= (txid, ind) => { },
    failCallback = (txid, ind) => false,
    block,
    beforeTransactions = [],
    afterTransactions = []
) => {
    try {
        if (!wallet.publicKey) throw new WalletNotConnectedError();

        const unsignedTxns = beforeTransactions;

        if (!block) {
            block = await connection.getRecentBlockhash(commitment);
        }

        for (let i = 0; i < instructionSet.length; i++) {
            const instructions = instructionSet[i];
            const signers = signersSet[i];

            if (instructions.length === 0) {
                continue;
            }

            let transaction = new Transaction();
            instructions.forEach((instruction) => transaction.add(instruction));
            transaction.recentBlockhash = block.blockhash;
            transaction.setSigners(
                // fee payed by the wallet owner
                wallet.publicKey,
                ...signers.map((s) => s.publicKey)
            );

            if (signers.length > 0) {
                transaction.partialSign(...signers);
            }

            unsignedTxns.push(transaction);
        }
        unsignedTxns.push(...afterTransactions);

        const partiallySignedTransactions = unsignedTxns.filter((t) =>
            t.signatures.find((sig) => sig.publicKey.equals(wallet.publicKey))
        );
        const fullySignedTransactions = unsignedTxns.filter(
            (t) => !t.signatures.find((sig) => sig.publicKey.equals(wallet.publicKey))
        );
        let signedTxns = await wallet.signAllTransactions(
            partiallySignedTransactions
        );
        signedTxns = fullySignedTransactions.concat(signedTxns);
        const pendingTxns= [];

        for (let i = 0; i < signedTxns.length; i++) {
            const signedTxnPromise = sendSignedTransaction({
                connection,
                signedTransaction: signedTxns[i],
            });

            if (sequenceType !== "Parallel") {
                try {
                    await signedTxnPromise.then(({ txid, slot }) =>
                        successCallback(txid, i)
                    );
                    pendingTxns.push(signedTxnPromise);
                } catch (e) {
                    if (process.env.REACT_APP_SHOW_ERRORS) {
                        console.log("Failed at txn index:", i);
                        console.log("Caught failure:", e);
                    }
                    failCallback(signedTxns[i], i);
                    if (sequenceType === "StopOnFailure") {
                        return {
                            number: i,
                            txs: await Promise.all(pendingTxns),
                        };
                    }
                }
            } else {
                pendingTxns.push(signedTxnPromise);
            }
        }

        if (sequenceType !== "Parallel") {
            const result = await Promise.all(pendingTxns);
            return { number: signedTxns.length, txs: result };
        }

        return { number: signedTxns.length, txs: await Promise.all(pendingTxns) };
    } catch (error) {
        throw error;
    }
};

export const getUnixTs = () => {
    return new Date().getTime() / 1000;
};

export async function sendSignedTransaction({
    signedTransaction,
    connection,
    timeout = DEFAULT_TIMEOUT,
}) {
    try {
        const rawTransaction = signedTransaction.serialize();

        const startTime = getUnixTs();
        let slot = 0;
        const txid = await connection.sendRawTransaction(
            rawTransaction,
            {
                skipPreflight: true,
            }
        );

        let done = false;
        (async () => {
            while (!done && getUnixTs() - startTime < timeout) {
                connection.sendRawTransaction(rawTransaction, {
                    skipPreflight: true,
                });
                await sleep(500);
            }
        })();
        try {
            const confirmation = await awaitTransactionSignatureConfirmation(
                txid,
                timeout,
                connection,
                "recent",
                true
            );

            if (!confirmation)
                throw new Error("Timed out awaiting confirmation on transaction");

            if (confirmation.err) {
                console.error(confirmation.err);
                throw new Error("Transaction failed: Custom instruction error");
            }

            slot = confirmation?.slot || 0;
        } catch (err) {
            console.error("Timeout Error caught", err);
            if (err.timeout) {
                throw new Error("Timed out awaiting confirmation on transaction");
            }
            let simulateResult = null;
            try {
                simulateResult = (
                    await simulateTransaction(connection, signedTransaction, "single")
                ).value;
            } catch (e) {
                throw e;
            }
            if (simulateResult && simulateResult.err) {
                if (simulateResult.logs) {
                    for (let i = simulateResult.logs.length - 1; i >= 0; --i) {
                        const line = simulateResult.logs[i];
                        if (line.startsWith("Program log: ")) {
                            throw new Error(
                                "Transaction failed: " + line.slice("Program log: ".length)
                            );
                        }
                    }
                }
                throw new Error(JSON.stringify(simulateResult.err));
            }
            // throw new Error('Transaction failed');
        } finally {
            done = true;
        }

        return { txid, slot };
    } catch (error) {
        throw error;
    }
}

async function simulateTransaction(
    connection,
    transaction,
    commitment
){
    try {
        // @ts-ignore
        transaction.recentBlockhash = await connection._recentBlockhash(
            // @ts-ignore
            connection._disableBlockhashCaching
        );

        const signData = transaction.serializeMessage();
        // @ts-ignore
        const wireTransaction = transaction._serialize(signData);
        const encodedTransaction = wireTransaction.toString("base64");
        const config = { encoding: "base64", commitment };
        const args = [encodedTransaction, config];

        // @ts-ignore
        const res = await connection._rpcRequest("simulateTransaction", args);
        if (res.error) {
            throw new Error("failed to simulate transaction: " + res.error.message);
        }
        return res.result;
    } catch (error) {
        throw error;
    }
}

async function awaitTransactionSignatureConfirmation(
    txid,
    timeout,
    connection,
    commitment = "recent",
    queryStatus = false
){
    try {
        let done = false;
        let status = {
            slot: 0,
            confirmations: 0,
            err: null,
        };
        let subId = 0;
        status = await new Promise(async (resolve, reject) => {
            setTimeout(() => {
                if (done) {
                    return;
                }
                done = true;
                reject({ timeout: true });
            }, timeout);
            try {
                subId = connection.onSignature(
                    txid,
                    (result, context) => {
                        done = true;
                        status = {
                            err: result.err,
                            slot: context.slot,
                            confirmations: 0,
                        };
                        if (result.err) {
                            if (process.env.REACT_APP_SHOW_ERRORS) {
                                console.log("Rejected via websocket", result.err);
                            }
                            reject(status);
                        } else {
                            resolve(status);
                        }
                    },
                    commitment
                );
            } catch (e) {
                done = true;
                console.error("WS error in setup", txid, e);
                throw e;
            }
            while (!done && queryStatus) {
                // eslint-disable-next-line no-loop-func
                (async () => {
                    try {
                        const signatureStatuses = await connection.getSignatureStatuses([
                            txid,
                        ]);
                        status = signatureStatuses && signatureStatuses.value[0];
                        if (!done) {
                            if (!status) {
                            } else if (status.err) {
                                if (process.env.REACT_APP_SHOW_ERRORS) {
                                    console.log("REST error for", txid, status);
                                }
                                done = true;
                                reject(status.err);
                            } else if (!status.confirmations) {
                            } else {
                                done = true;
                                resolve(status);
                            }
                        }
                    } catch (e) {
                        if (!done) {
                            if (process.env.REACT_APP_SHOW_ERRORS) {
                                console.log("REST connection error: txid", txid, e);
                            }
                        }
                        throw e;
                    }
                })();
                await sleep(2000);
            }
        });

        if (
            //@ts-ignore
            connection._signatureSubscriptions &&
            //@ts-ignore
            connection._signatureSubscriptions[subId]
        )
            connection.removeSignatureListener(subId);
        done = true;

        return status;
    } catch (error) {
        throw error;
    }
}


export const sleep = (duration) => {
    return new Promise((resolve) => {
        setTimeout(resolve, duration)
    })
}