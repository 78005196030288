import {useState} from "react";
import styled from "styled-components";

const Image = styled.img`
  position: relative;
  top: 0;
  height: auto;
  max-width: 100%;
  z-index: 2;
`;

const Placeholder = styled.div`
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding-bottom: 100%;
  z-index: 2;
`;

export function NFTItemImage({ src }) {
    const [loading, setLoading] = useState(true)

    return (
        <>
            <Image src={src} style={ loading ? {display: 'none'}: {}} onLoad={() => (setLoading(false))}/>
            <Placeholder style={ !loading ? {display: 'none'}: {}}>
                <div className="loader loader-1">
                    <div className="loader-outter"></div>
                    <div className="loader-inner"></div>
                </div>
            </Placeholder>
        </>
    )
}