import { Metaplex, keypairIdentity } from "@metaplex-foundation/js";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import idl from "../lib/nyan_staking_idl.json";
import { Buffer } from "buffer";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import * as axios from 'axios';

const url = `https://api.helius.xyz/v0/tokens/metadata?api-key=` + process.env.REACT_APP_API_KEY;


export const fetchNFTsOwnedByWallet = async (userWallet, connection) => {
  const keypair = Keypair.generate();
  const isArweaveLoading = await checkArweaveLoading();
  if (isArweaveLoading) {
    const metaplex = new Metaplex(connection);
    metaplex.use(keypairIdentity(keypair));
    const allNFTs = await metaplex.nfts().findAllByOwner({ owner: userWallet });
    const nyanNFTs = allNFTs.filter(k => k.updateAuthorityAddress.toBase58() === process.env.REACT_APP_EXPEDITIONS_UPDATE_AUTHORITY);
    const populateTokensMetadata = await Promise.all(
      nyanNFTs.map(async (nft) => {
        if (!nft) {
          return;
        }
        const populatedNft = await metaplex
          .nfts()
          .load({ metadata: nft });

        return populatedNft;
      }, [])
    );


    const provider = new anchor.AnchorProvider(connection, userWallet, {
      skipPreflight: true
    });

    const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)
    const stakedMetadata = [];
    await connection.getProgramAccounts(program.programId, {
      filters: [
        {
          "memcmp": {
            "offset": 8,
            "bytes": userWallet.toString()
          }
        }
      ]
    }).then(async response => {
      for (const acc of response) {
        await program.account.stakeNyanMetadata.fetch(acc.pubkey).then(async response => {
          const nftData = await metaplex.nfts().findByMint({ mintAddress: new PublicKey(response.receiverNyanMintAccount) });
          stakedMetadata.push({
            metadata: nftData,
            stakedData: response
          })
        }).catch(error => {
          console.log(error)
        })
      }
    })

    let stakedMetadataDeserialized = stakedMetadata
      ?.map((info) =>
        info?.metadata !== undefined
          ? { stakedData: info.stakedData, ...info.metadata }
          : undefined
      )

    return stakedMetadataDeserialized.concat(populateTokensMetadata);
  }else {
    const allTokens = await connection.getParsedTokenAccountsByOwner( new PublicKey(userWallet), {programId:TOKEN_PROGRAM_ID});
    const tokens = allTokens.value.filter(k => k.account.data.parsed.info.tokenAmount.amount == "1" && k.account.data.parsed.info.tokenAmount.decimals == 0);
    const allNFTs = await getMetadata(tokens.map(k => k.account.data.parsed.info.mint));
    const verifiedNFTs =  allNFTs.filter((k) => k.onChainData && k.onChainData.updateAuthority ==  process.env.REACT_APP_EXPEDITIONS_UPDATE_AUTHORITY);
    const data = verifiedNFTs.map(obj => {
      return {
        ...obj.onChainData,
        mint: { address: new PublicKey(obj.mint)},
        json: {
          ...obj.offChainData,
          image : obj.offChainData.image.replace("https://www.arweave.net", "https://www.ar-io.net")
        }
      };
    });

    const provider = new anchor.AnchorProvider(connection, userWallet, {
      skipPreflight: true
    });

    const program = new anchor.Program(idl, process.env.REACT_APP_EXPEIDTIONS_PROGRAM_ID, provider)
    const stakedMetadata = [];
    await connection.getProgramAccounts(program.programId, {
      filters: [
        {
          "memcmp": {
            "offset": 8,
            "bytes": userWallet.toString()
          }
        }
      ]
    }).then(async response => {
      for (const acc of response) {
        await program.account.stakeNyanMetadata.fetch(acc.pubkey).then(async response => {
          const nftData = await getMetadata([new PublicKey(response.receiverNyanMintAccount)]);
          stakedMetadata.push({
            metadata:nftData.map(obj => {
              return {
                ...obj.onChainData,
                mint: { address: new PublicKey(obj.mint)},
                json: {
                  ...obj.offChainData,
                  image : obj.offChainData.image.replace("https://www.arweave.net", "https://www.ar-io.net")
                }
              };
            })[0],
            stakedData: response
          })
        }).catch(error => {
          console.log(error)
        })
      }
    })


    let stakedMetadataDeserialized = stakedMetadata
      ?.map((info) =>
        info?.metadata !== undefined
          ? { stakedData: info.stakedData, ...info.metadata }
          : undefined
      )

    return stakedMetadataDeserialized.concat(data);
  }
};

export async function fetchMetadataAccountForNFT(nftMintKey, connection) {
  const metadataBuffer = Buffer.from("metadata");
  const metadataProgramIdPublicKey = new PublicKey(
    "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
  );

  const metadataAccount = (
    await PublicKey.findProgramAddress(
      [
        metadataBuffer,
        metadataProgramIdPublicKey.toBuffer(),
        nftMintKey.toBuffer(),
      ],
      metadataProgramIdPublicKey
    )
  )[0];

  return metadataAccount;
}

const checkArweaveLoading = async () => {
  try {
    const response = await fetch('https://arweave.net/');
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};


const getMetadata = async (nftAddresses) => {
  const chunks = chunk(nftAddresses, 100)
  const promises = chunks.map(async (chunk) => {
    const { data } = await axios.post(url, {
      mintAccounts: chunk,
      includeOffChain: true
    })
    return data
  })

  const results = await Promise.all(promises)
  const merged = [].concat(...results)
  return merged;
};


const chunk = (array, size) => {
  return array.reduce((chunks, element, index) => {
    if (index % size === 0) {
      chunks.push([element])
    } else {
      chunks[chunks.length - 1].push(element)
    }
    return chunks
  }, [])
}
