import { useState, useEffect } from "react";
import styled from "styled-components";
import {UnStake} from "./UnStake";
import {NFTItemImage} from "./NFTItemImage";
import FetchNyansDefault from "../assets/images/fetch-nyans-default.png";
import FetchNyansHover from "../assets/images/fetch-nyans-hover.png";
import FinishDefault from "../assets/images/finish-default.png"
import FinishHover from "../assets/images/finish-hover.png"
import EmbarkDefault from "../assets/images/embark-default.png"
import EmbarkHover from "../assets/images/embark-hover.png"

const Image = styled.img`
  position: relative;
  top: 0;
  height: auto;
  max-width: 100%;
  z-index: 2;
`;

const ItemContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.6);
`;

const InfoContainer = styled.div`
  padding: 15px;
`;

const Title = styled.div`
  width: 100%;
  padding-top: 15px;
  font-family: 'Gellix', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  z-index: 3;
  ::before{
    content: ' ';
    position: absolute;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #000721 0%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
    top: 0;
    left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
`;


const Button = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  outline: none !important;

  font-family: 'Gellix';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  text-decoration-line: underline;
  color: #FFFFFF;
  
  &:hover {
    cursor: pointer;
  }
`;

const Multiplier = styled.div`
  position: relative;
  width: 126px;
  height: 18px;
  padding: 4px 0;
  margin-top: 10px;
  line-height: 12px;
  font-family: 'Gellix', serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  background: #C34BFB;
  border-radius: 15px;
  color: #fff;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
`

const ItemWrapper = styled.div`
  padding: 0 10px;
`

const Exploring = styled.div`
  background: #257A5E;
  padding: 15px 0;
  text-align: center;
`;

const ExploringTitle = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding-top: 8px;
`;

const ExploringEarnings = styled.div`
  font-family: 'Gellix', serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
`;

const ImageContainer = styled.div`
  position: relative;
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.3);
  z-index: 10;
  border-radius: 4px;
`;

const ImageOverlayText = styled.button`
  position: relative;
  height: 49px;
  font-family: 'Gellix';
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 30px;
  color: #fff;
  border: none;
  cursor: pointer;
  background: rgba(37, 122, 94, 0.8);
  transform: rotate(-10.25deg);
  z-index: 2;
`;

export function NFTItem({ item, setSelectedNFT }) {
  const [NFTData, setNFTData] = useState(null);
  const [isHovered, setHover] = useState(false);

  useEffect(() => {
    async function getData() {
      let data = item;
      setNFTData(data);
    }
    getData();
  }, [item]);

  const multipliers = {
    'Common': 'x1',
    'Uncommon': 'x1.59',
    'Rare': 'x3.29',
    'Ultrarare': 'x6.57',
    'Legendary': 'x11.5'
  };

  const duration = {
    seven: 7,
    fourteen: 14,
    thirty: 30
  }

  function rarity() {
    return NFTData?.json.attributes.filter(trait => (trait.trait_type && trait.trait_type === 'Rarity') || (trait.traitType && trait.traitType === "Rarity"))[0].value
  }

  function calculateEarnings() {
    const distribution = {
      'Common': {
        7: 152,
        14: 365,
        30: 978
      },
      'Uncommon': {
        7: 242,
        14: 581,
        30: 1555
      },
      'Rare': {
        7: 501,
        14: 1202,
        30: 3219
      },
      'Ultrarare': {
        7: 1000,
        14: 2400,
        30: 6427
      },
      'Legendary': {
        7: 1750,
        14: 4200,
        30: 11250
      }
    }

    return distribution[rarity()][duration[Object.keys(item.stakedData.duration)[0]]] * (calculateProgress() / 100)
  }

  function calculateProgress() {
    if (!item.stakedData) {
      return 0;
    }

    let calculatedDuration = duration[Object.keys(item.stakedData.duration)[0]]
    calculatedDuration = calculatedDuration * 86400;
    const countDownDate = new Date(item.stakedData.endTime.toNumber() * 1000).getTime()
    const startDate = new Date((item.stakedData.endTime.toNumber() - calculatedDuration) * 1000).getTime();

// Update the count down every 1 second
// Get todays date and time
    var now = new Date().getTime();

// Find the distance between now and the count down date
    var distanceWhole = countDownDate - startDate;
    var distanceLeft = countDownDate - now;

// Time calculations for minutes and percentage progressed
    var secondsLeft = Math.floor(distanceLeft / 1000);
    var secondsTotal = Math.floor(distanceWhole / 1000);
    var progress = ((secondsTotal - secondsLeft) / secondsTotal) * 100;

    return progress < 100 ? progress : 100
  }

  function calculateMissingTime(){
    const countDownDate = new Date(item.stakedData.endTime.toNumber() * 1000);
    const startDate = new Date();

    let seconds = Math.floor((countDownDate - (startDate))/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    const days = Math.floor(hours/24);

    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

    if (days <= -1 || hours <= -1 || minutes <= -1) {
      return "Finished";
    } else {
      return `${days}D ${hours}H ${minutes}M`
    }
  }

  return (
      <ItemWrapper className="col-12 col-sm-6 col-md-6 col-lg-4">
        <ItemContainer
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
          { NFTData && item.stakedData ? (
              <div>
                <InfoContainer>
                  <ImageContainer>
                    <NFTItemImage src={NFTData?.json.image} />
                      <ImageOverlay>
                        { calculateProgress() < 100 ? (
                            <ImageOverlayText>Exploring</ImageOverlayText>
                        ) : (
                            <ImageOverlayText className="completed">Completed</ImageOverlayText>
                        )}
                      </ImageOverlay>
                  </ImageContainer>
                  <Title className="text-center">{item.name}</Title>
                  <Multiplier>{multipliers[rarity()]} Multiplier</Multiplier>
                </InfoContainer>
                <Exploring>
                  <ExploringEarnings>$CTNP Earned: { calculateEarnings().toFixed(2) }</ExploringEarnings>
                  <ExploringEarnings>{ calculateMissingTime() }</ExploringEarnings>
                  { calculateProgress() < 100 ? (
                      <Button className="w-75" onClick={() => (setSelectedNFT(item))}>
                        Cancel mission
                      </Button>
                  ) : (
                      <Button className="w-75" onClick={() => (setSelectedNFT(item))}>
                        <img className="img-fluid" src={FinishDefault} onMouseOver={e => (e.currentTarget.src = FinishHover)} onMouseOut={e => (e.currentTarget.src = FinishDefault)}/>
                      </Button>
                  )}
                </Exploring>
              </div>
          ) : (
              <div>
                <InfoContainer>
                  <NFTItemImage src={NFTData?.json.image} />
                  <Title className="text-center">{NFTData?.json.name}</Title>
                  {/* <Multiplier>{multipliers[rarity()]} Multiplier</Multiplier> */}
                </InfoContainer>
                <div className="d-flex justify-content-center w-100">
                  {/* <Button className="w-75" onClick={() => (setSelectedNFT(item))}>
                    <img className="img-fluid" src={EmbarkDefault} onMouseOver={e => (e.currentTarget.src = EmbarkHover)} onMouseOut={e => (e.currentTarget.src = EmbarkDefault)}/>
                  </Button> */}
                </div>
              </div>
          )}
        </ItemContainer>
      </ItemWrapper>
  );
}
